import React, { FunctionComponent } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import SEO from '../components/seo';

const About: FunctionComponent = () => (
  <Layout>
    <SEO title="About" />

    <div className="relative">
      <div className="hidden sm:block">
        <StaticImage alt="" className="hero-image" src="../images/about-hero.png" />
      </div>

      <div className="block sm:hidden">
        <StaticImage alt="" src="../images/about-hero-mobile.png" />
      </div>

      <div className="absolute bg-black bg-opacity-40 inset-0" />

      <div className="absolute left-1/2 min-w-full px-4 text-center text-white top-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <h1 className="font-Parisienne mb-8 text-6xl md:text-7xl xl:text-8xl">
          About Us
        </h1>

        <p className="font-medium max-w-md mx-auto text-lg">
          Our care for quality and our customers is what sets Natural Cafe apart from competitors.
        </p>
      </div>
    </div>

    <div className="bg-beige container mx-auto px-4">
      <div className="mb-24 pt-24">
        <div className="gap-8 grid items-center md:grid-cols-2 xl:gap-32">
          <div>
            <StaticImage alt="" src="../images/about-mission.png" />
          </div>

          <div>
            <h3 className="text-green uppercase">6 Years Strong</h3>
            <h2 className="font-Parisienne my-6 text-4xl lg:text-6xl">Our Mission</h2>

            <p className="font-light xl:w-2/3">
              Natural Cafe opened in Arlington, VA in 2014. Our mission at Natural Cafe is to
              offer our customers wholesome and delicious deli meats and cheeses, accompanied with
              excellent customer service.
            </p>
          </div>
        </div>
      </div>

      <div className="my-24">
        <div className="gap-8 grid items-center md:grid-cols-2 xl:gap-32">
          <div className="order-2 px-4 md:order-1 md:pr-0 xl:pl-16">
            <h3 className="text-green uppercase">Gourmet Deli &amp; Market</h3>
            <h2 className="font-Parisienne my-6 text-4xl lg:text-6xl">Natural Market</h2>

            <p className="font-light xl:w-2/3">
              Natural Cafe is proud to serve fresh, made-from-scratch food,
              featuring Boar&apos;s Head brand meats and cheeses. Boar&apos;s Head products
              have no fillers, artificial colors, flavors, or trans fats. They are also gluten
              free! Our menu has plenty to offer, with great things for any time of the day.
              Breakfast items, sandwiches, soups, paninis, and salads are right here waiting.
              Also featured on our menu are our high quality, organic gourmet foods and
              grocery products.
            </p>
          </div>

          <div className="order-1 pl-4 md:order-2 md:pl-0">
            <StaticImage alt="" src="../images/about-market.png" />
          </div>
        </div>
      </div>

      <div className="pb-24">
        <div className="gap-8 grid items-center md:grid-cols-2 xl:gap-32">
          <div>
            <StaticImage alt="" src="../images/about-catering.png" />
          </div>

          <div>
            <h3 className="text-green uppercase">Catering Services</h3>
            <h2 className="font-Parisienne my-6 text-4xl lg:text-6xl">
              Have a big event coming up?
            </h2>

            <p className="font-light xl:w-2/3">
              Let Natural Cafe take care of feeding your guests with our custom made
              catering platters and buffet selection. At Natural Cafe, we look forward
              to making your day better through great food and great service.
            </p>

            <a
              className="bg-green bg-opacity-20 border-2 border-green inline-block mt-6 px-6 py-2 rounded-md text-green transition-opacity hover:opacity-70"
              href="https://order.ubereats.com/washington-dc/food-delivery/Natural%20Cafe%20(Arlington)/ukjUWM-yTFyEVNZHu2LcbA/?utm_source=web-restaurant-manager"
            >
              Place Order
            </a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default About;
